import React, { useEffect } from "react";

function TurnStile({ token, setToken }) {
  useEffect(() => {
    try {
      if (token) return;
      const onloadTurnstileCallback = () => {
        console.log("Turnstile script loaded");
        window.turnstile.render("#turnstile-container", {
          sitekey: "0x4AAAAAAAlzSCnTBNU17nAo",
          callback: (token) => {
            console.log("callback token", token);
            setToken(token);
          },
        });
      };

      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback";
      script.async = true;
      script.onload = onloadTurnstileCallback;
      document.body.appendChild(script);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div
      id="turnstile-container"
      data-size="flexible"
      data-theme="light"
      data-language={"th-th"}
      // data-feedback-enabled={true}
      // data-retry={"never"}
      // data-refresh-expired={"manual"}
    ></div>
  );
}

export default TurnStile;
