import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import api from "../util/api";
import { Form, Button, TextField, Link, Box, Typography, CircularProgress, IconButton, InputAdornment, Avatar, Alert } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Popover } from "antd";

import Layout from "../components/Layout";
import Background from "/images/411440x810.jpeg";
import Logo from "/images/logo/iseelogo1.png";
import Swal from "sweetalert2";
import "./index.css";
import { validateRequire, validateRegex, validatePassword, validateEmail, validateMinCharacter, validateCompare } from "../util/validate";
import RecaptchaModal from "../components/RecaptchaModal";
import axios from "axios";
import TurnStile from "../components/TurnStile";

export default function SignUp(props) {
  const [translate, setTranslate] = useState(null);
  const [lang, setLang] = useState(null);

  const [showPass, setShowPass] = useState({
    pass: false,
    confirmpass: false,
  });

  const [validate_field, setValidateField] = useState({
    user: [],
    pass: [],
    confirmpass: [],
    name: [],
    lastname: [],
    email: [],
    phone_number: [],
    department: [],
  });
  const [alert_err, setAlertErr] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [user_data, setUserData] = useState({
    user: props?.location?.state?.profile?.line?.name ?? "",
    pass: "",
    confirmpass: "",
    name: "",
    lastname: "",
    email: props?.location?.state?.profile?.line?.email ?? "",
    phone_number: "",
    department: "",
  });
  const [captcha, setCaptcha] = useState({
    open: false,
    isLoading: false,
    data: null,
  });
  const [token, setToken] = useState(null);

  let [validatePass, setValidatePass] = useState(validatePassword(user_data.pass));
  let [validateConfirmPass, setValidateConfirmPass] = useState(validatePassword(user_data.confirmpass));

  const handleSubmit = async () => {
    // console.log("submit", event);
    // event.preventDefault();

    let check_validate = false;
    let register_validate = onValidate(user_data);
    for (let key in register_validate) {
      check_validate = register_validate[key].filter((item) => item !== null).length > 0;
      if (check_validate) break;
    }
    if (!check_validate) {
      if (window.turnstile.isExpired("#turnstile-container")) {
        window.turnstile.reset("#turnstile-container");
        return Swal.fire("", "โปรดยืนยันตัวตน", "error");
      } else {
        let data = user_data;
        setOnLoad(true);
        onSignup(data);
      }
    }
  };

  const onValidate = (data) => {
    let reg_text_en = /^[a-zA-Z0-9-_]+$/;
    let reg_text_number = /^[0-9]+$/;
    let reg_text_special = /^[^!*\/=+<>±÷×`{}|:]+$/;

    validate_field.user = [];
    validate_field.user.push(validateRequire(data.user), validateMinCharacter(data.user, 3), validateRegex(data.user, reg_text_en, "a - Z, 0 - 9"));

    validate_field.pass = [];
    validate_field.pass.push(
      validateRequire(data.pass),
      Object.keys(validatePassword(data.pass)).filter((x) => !validatePassword(data.pass)[x].isvalid).length > 0
        ? "เงื่อนไขการตั้งรหัสผ่านไม่ผ่าน"
        : null
    );

    validate_field.confirmpass = [];
    validate_field.confirmpass.push(
      validateRequire(data.confirmpass),
      validateCompare(data.pass, data.confirmpass, "รหัสผ่านไม่ตรงกัน"),
      Object.keys(validatePassword(data.confirmpass)).filter((x) => !validatePassword(data.confirmpass)[x].isvalid).length > 0
        ? "เงื่อนไขการตั้งรหัสผ่านไม่ผ่าน"
        : null
    );

    validate_field.name = [];
    validate_field.name.push(validateRequire(data.name), validateRegex(data.name, reg_text_special, "ไม่สามารถใส่อักขระพิเศษ"));

    validate_field.lastname = [];
    validate_field.lastname.push(validateRequire(data.lastname), validateRegex(data.lastname, reg_text_special, "ไม่สามารถใส่อักขระพิเศษ"));

    validate_field.email = [];
    validate_field.email.push(validateRequire(data.email), validateEmail(data.email));

    validate_field.phone_number = [];
    validate_field.phone_number.push(
      validateRequire(data.phone_number),
      validateRegex(data.phone_number, reg_text_number, "ตัวเลข 0 - 9 เท่านั้น ตัวอย่าง : 0815623548")
    );

    validate_field.department = [];
    validate_field.department.push(validateRequire(data.department), validateRegex(data.department, reg_text_special, "ไม่สามารถใส่อักขระพิเศษ"));

    setValidateField({ ...validate_field });

    return validate_field;
  };

  const onSignup = async (data) => {
    try {
      let data_regis = {
        user: data.user,
        pass: data.pass,
        application: "isee",
        profile: {
          name: data.name,
          lastname: data.lastname,
          email: data.email.toLowerCase(),
          phone_number: data.phone_number,
          department: data.department,
          facebook_line: "",
          system: "isee",
        },
        token,
        // "roles": []
      };
      if (props.location.state) {
        if (props?.location?.state?.profile?.id) {
          data_regis.lineid = props?.location?.state?.profile?.id;
          data_regis.line_connect = true;
        }
      }
      const res = await api.register(data_regis);
      setOnLoad(false);
      Swal.fire("", "ลงทะเบียนสำเร็จแล้ว", "success");
      if (res.ok) {
        setCaptcha({ open: false, data: null });
        if (props.location.state) {
          if (props.location.state.profile.id) {
            let loginres = await api.auth({
              user: data.user,
              pass: data.pass,
            });
            navigate(`/`);
          } else {
            setTimeout(() => {
              navigate(`/login`);
            }, 3000);
          }
        } else {
          setTimeout(() => {
            navigate(`/login`);
          }, 3000);
        }
      }
    } catch (err) {
      window.turnstile.reset("#turnstile-container");
      let text_alert = "";
      if (Array.isArray(err)) {
        err.forEach((ele, idx) => {
          idx === err.length - 1 ? (text_alert += ele) : (text_alert += ele + ", ");
        });
      } else {
        text_alert = err;
      }
      setCaptcha({ open: false, data: null });
      setOnLoad(false);
      setAlertErr(text_alert);
    }
  };

  let contentPass = (
    <div>
      {Object.keys(validatePass).map((x) => {
        let _data = validatePass[x];
        if (_data.isvalid) {
          return <p className="line-through">- {_data.text}</p>;
        } else {
          return <p className="text-red-500">- {_data.text}</p>;
        }
      })}
    </div>
  );

  let contentConfirmPass = (
    <div>
      {Object.keys(validateConfirmPass).map((x) => {
        let _data = validateConfirmPass[x];
        if (_data.isvalid) {
          return <p className="line-through">- {_data.text}</p>;
        } else {
          return <p className="text-red-500">- {_data.text}</p>;
        }
      })}
    </div>
  );

  return (
    <Layout
      getLang={(translate, lang) => {
        setTranslate(translate);
        setLang(lang);
      }}
    >
      <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Background})` }}>
        <div className="min-h-[100vh] flex justify-end xl:px-16 xl:pt-[100px] xl:pb-[100px]">
          <div className="w-full xl:w-[580px] rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
            <Box className="flex flex-col items-center py-32 px-8 xl:py-2 xl:px-16">
              {props.location.state ? (
                <Avatar
                  className="my-4"
                  alt={props?.location?.state?.profile?.line?.name}
                  src={props?.location?.state?.profile?.line.img}
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <img src={Logo} alt="" style={{ width: 200 }} />
              )}

              <Typography component="h1" variant="h5">
                {props.location.state ? `LINE : ${props?.location?.state?.profile?.line.name}` : translate?.ISEE_User_Registration}
              </Typography>

              <form className=" w-full md:w-[450px] grid grid-cols-4 gap-x-4">
                <TextField
                  className="col-span-4 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, user: e.target.value })}
                  required
                  error={validate_field.user.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.user.filter((item) => item !== null).length > 0 ? validate_field.user.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="username"
                  name="username"
                  label="Username"
                  value={user_data.user}
                  inputProps={{ maxLength: 50 }}
                />
                <Popover placement="bottom" title="เงื่อนไขการตั้งรหัสผ่าน" content={contentPass} trigger="focus">
                  <TextField
                    className="col-span-4 md:col-span-2 bg-gray-100"
                    onChange={(e) => {
                      setValidatePass({ ...validatePassword(e.target.value) });
                      setUserData({ ...user_data, pass: e.target.value });
                    }}
                    required
                    error={validate_field.pass.filter((item) => item !== null).length > 0}
                    helperText={
                      validate_field.pass.filter((item) => item !== null).length > 0 ? validate_field.pass.filter((item) => item !== null)[0] : ""
                    }
                    margin="normal"
                    id="password"
                    type={showPass.pass ? "text" : "password"}
                    name="password"
                    label="Password"
                    InputProps={{
                      maxLength: 50,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass({ ...showPass, pass: !showPass.pass });
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPass.pass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Popover>
                <Popover placement="bottom" title="เงื่อนไขการตั้งรหัสผ่าน" content={contentConfirmPass} trigger="focus">
                  <TextField
                    className="col-span-4 md:col-span-2 bg-gray-100"
                    // onChange={(e) => setUserData({ ...user_data, confirmpass: e.target.value })}
                    onChange={(e) => {
                      setValidateConfirmPass({ ...validatePassword(e.target.value) });
                      setUserData({ ...user_data, confirmpass: e.target.value });
                    }}
                    required
                    error={validate_field.confirmpass.filter((item) => item !== null).length > 0}
                    helperText={
                      validate_field.confirmpass.filter((item) => item !== null).length > 0
                        ? validate_field.confirmpass.filter((item) => item !== null)[0]
                        : ""
                    }
                    margin="normal"
                    id="confirmpass"
                    type={showPass.confirmpass ? "text" : "password"}
                    name="confirmpass"
                    label="Confirm Password"
                    InputProps={{
                      maxLength: 50,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPass({ ...showPass, confirmpass: !showPass.confirmpass });
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPass.confirmpass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Popover>

                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, name: e.target.value })}
                  required
                  error={validate_field.name.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.name.filter((item) => item !== null).length > 0 ? validate_field.name.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="firstname"
                  name="firstname"
                  label={translate?.fristname}
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, lastname: e.target.value })}
                  required
                  error={validate_field.lastname.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.lastname.filter((item) => item !== null).length > 0
                      ? validate_field.lastname.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="lastname"
                  name="lastname"
                  label={translate?.lastname}
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, email: e.target.value })}
                  required
                  error={validate_field.email.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.email.filter((item) => item !== null).length > 0 ? validate_field.email.filter((item) => item !== null)[0] : ""
                  }
                  margin="normal"
                  id="email"
                  name="email"
                  label="Email"
                  value={user_data.email}
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  className="col-span-4 md:col-span-2 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, phone_number: e.target.value })}
                  required
                  error={validate_field.phone_number.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.phone_number.filter((item) => item !== null).length > 0
                      ? validate_field.phone_number.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="tel"
                  name="tel"
                  label={translate?.phone}
                  inputProps={{ maxLength: 10 }}
                />

                <TextField
                  className="col-span-4 bg-gray-100"
                  onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                  required
                  error={validate_field.department.filter((item) => item !== null).length > 0}
                  helperText={
                    validate_field.department.filter((item) => item !== null).length > 0
                      ? validate_field.department.filter((item) => item !== null)[0]
                      : ""
                  }
                  margin="normal"
                  id="department"
                  name="department"
                  label={translate?.agency}
                  inputProps={{ maxLength: 50 }}
                />
                <div className="col-span-4">
                  {alert_err !== "" ? (
                    <Alert variant="outlined" severity="error">
                      {alert_err}
                    </Alert>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-span-4 mt-4">
                  <TurnStile token={token} setToken={setToken} />
                </div>

                <div className="col-span-4 mt-4">
                  <Button
                    // type="submit"
                    disabled={onload}
                    style={{ height: 48 }}
                    fullWidth
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}
                  >
                    {onload ? translate?.registering : translate?.register}
                  </Button>
                </div>

                <div className="col-span-4 my-2">
                  <Link href="/login" variant="body2">
                    {translate?.have_an_account}
                  </Link>
                </div>
              </form>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
}
